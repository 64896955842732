/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container as ContainerComponent, ContainerProps } from 'components';
import { resolveStyleProp, resolveSx } from 'components/types';
import React, { ReactElement } from 'react';
import { getHash } from 'utils/hash';
import { memoize } from 'lodash';

import CreateScenarioFromScratch from './svg/icon-create-scenario-from-scratch.svg';
import NavigationMenuHorizontal from './svg/icon-navigation-menu-horizontal.svg';
import InformationCircleFilled from './svg/icon-information-circle-filled.svg';
import NavigationMenuVertical from './svg/icon-navigation-menu-vertical.svg';
import TargetIndicatorAttack from './svg/icon-target-indicator-attack.svg';
import TargetEnrichmentRule from './svg/icon-target-enrichment-rules.svg';
import PolicyBindingConsole from './svg/icon-policy-binding-console.svg';
import EnvironmentAdvanced from './svg/icon-environment-advanced.svg';
import AdviceActionNeeded from './svg/icon-advice-action-needed.svg';
import ChevronDoubleRight from './svg/icon-chevron-double-right.svg';
import DataTransferCircle from './svg/icon-data-transfer-circle.svg';
import PolicyBindingStateUI from './svg/icon-policy-binding-ui.svg';
import ResilienceAchieved from './svg/icon-resilience-achieved.svg';
import ChevronDoubleDown from './svg/icon-chevron-double-down.svg';
import ChevronDoubleLeft from './svg/icon-chevron-double-left.svg';
import CreateFromScratch from './svg/icon-create-from-scratch.svg';
import RoundSpinnerWhite from './svg/icon-round-spinner-white.svg';
import WarningCircleOpen from './svg/icon-warning-circle-open.svg';
import AdviceImplemented from './svg/icon-advice-implemented.svg';
import CalendarRecurrent from './svg/icon-calendar-recurrent.svg';
import ContainerRegistry from './svg/icon-container-registry.svg';
import EnvironmentGlobal from './svg/icon-environment-global.svg';
import ExperimentBlocked from './svg/icon-experiment-blocked.svg';
import InformationCircle from './svg/icon-information-circle.svg';
import SteadybitApproved from './svg/icon-steadybit-approved.svg';
import ChevronSmallDown from './svg/icon-chevron-small-down.svg';
import AdviceValidation from './svg/icon-advice-validation.svg';
import ChevronBeginning from './svg/icon-chevron-beginning.svg';
import ExperimentFailed from './svg/icon-experiment-failed.svg';
import ChevronDoubleUp from './svg/icon-chevron-double-up.svg';
import CalendarDisable from './svg/icon-calendar-disable.svg';
import ExperimentError from './svg/icon-experiment-error.svg';
import GoogleCloudPlatform from './svg/icon-google-cloud.svg';
import StatusOperating from './svg/icon-status-operating.svg';
import VerticalDivider from './svg/icon-vertical-divider.svg';
import ApiAccessToken from './svg/icon-api-access-token.svg';
import ChevronSmallUp from './svg/icon-chevron-small-up.svg';
import CalendarPaused from './svg/icon-calendar-paused.svg';
import CollectionCall from './svg/icon-connection-call.svg';
import ArrowDropDown from './svg/icon-arrow-drop-down.svg';
import AdviceGeneral from './svg/icon-advice-general.svg';
import AdviceUnknown from './svg/icon-advice-unknown.svg';
import AlertTriangle from './svg/icon-alert-triangle.svg';
import ArrowRight from './svg/icon-arrow-right-large.svg';
import CalendarClock from './svg/icon-calendar-clock.svg';
import GeneralAdvice from './svg/icon-general-advice.svg';
import KubernetesPod from './svg/icon-kubernetes-pod.svg';
import PolicyUnknown from './svg/icon-policy-unknown.svg';
import StatusWaiting from './svg/icon-status-waiting.svg';
import WarningCircle from './svg/icon-warning-circle.svg';
import ArrowRightSmall from './svg/icon-arrow-right.svg';
import Documentation from './svg/icon-documentation.svg';
import ActivityHide from './svg/icon-activity-hide.svg';
import ActivityShow from './svg/icon-activity-show.svg';
import AlertDiamond from './svg/icon-alert-diamond.svg';
import ArrowLeft from './svg/icon-arrow-left-large.svg';
import CalendarEdit from './svg/icon-calendar-edit.svg';
import ChevronRight from './svg/icon-chevron-right.svg';
import DiscoveryKit from './svg/icon-discovery-kit.svg';
import RefreshArrow from './svg/icon-refresh-arrow.svg';
import RoundSpinner from './svg/icon-round-spinner.svg';
import WarningSmall from './svg/icon-warning-small.svg';
import ArrowDropUp from './svg/icon-arrow-drop-up.svg';
import Distribution from './svg/icon-distribution.svg';
import Integrations from './svg/icon-integrations.svg';
import Notification from './svg/icon-notification.svg';
import RunAgain from './svg/icon-run-again-single.svg';
import TemplateAdd from './svg/icon-template-add.svg';
import BlastRadius from './svg/icon-blast-radius.svg';
import CheckFilled from './svg/icon-check-filled.svg';
import ChevronDown from './svg/icon-chevron-down.svg';
import ChevronLeft from './svg/icon-chevron-left.svg';
import Experiment2 from './svg/icon-experiment-2.svg';
import FailedRound from './svg/icon-failed-round.svg';
import MetricCheck from './svg/icon-metric-check.svg';
import MetricQuery from './svg/icon-metric-query.svg';
import TrendStatic from './svg/icon-trend-static.svg';
import WarningOpen from './svg/icon-warning-open.svg';
import Application from './svg/icon-application.svg';
import Description from './svg/icon-description.svg';
import Environment from './svg/icon-environment.svg';
import Information from './svg/icon-information.svg';
import Maintenance from './svg/icon-maintenance.svg';
import AgentCount from './svg/icon-agent-count.svg';
import AttackTime from './svg/icon-attack-time.svg';
import CheckRound from './svg/icon-check-round.svg';
import ChevronEnd from './svg/icon-chevron-end.svg';
import InProgress from './svg/icon-in-progress.svg';
import SpringBoot from './svg/icon-spring-boot.svg';
import Disconnect from './svg/icon-disconnect.svg';
import ContainerD from './svg/icon-containerd.svg';
import Deployment from './svg/icon-deployment.svg';
import Experiment from './svg/icon-experiment.svg';
import Kubernetes from './svg/icon-kubernetes.svg';
import Prometheus from './svg/icon-prometheus.svg';
import ActionKit from './svg/icon-action-kit.svg';
import ChevronUp from './svg/icon-chevron-up.svg';
import FavFilled from './svg/icon-fav-filled.svg';
import SaveDraft from './svg/icon-save-draft.svg';
import TrendDown from './svg/icon-trend-down.svg';
import ZoomMinus from './svg/icon-zoom-minus.svg';
import Astronaut from './svg/icon-astronaut.svg';
import Clipboard from './svg/icon-clipboard.svg';
import Container from './svg/icon-container.svg';
import Duplicate from './svg/icon-duplicate.svg';
import Explosion from './svg/icon-explosion.svg';
import Extension from './svg/icon-extension.svg';
import Lightbulb from './svg/icon-lightbulb.svg';
import Namespace from './svg/icon-namespace.svg';
import Openshift from './svg/icon-openshift.svg';
import RunV2 from './svg/icon-run-single-v2.svg';
import SandGlass from './svg/icon-sandglass.svg';
import Unarchive from './svg/icon-unarchive.svg';
import Unchecked from './svg/icon-unchecked.svg';
import NewRelic from './svg/icon-new-relic.svg';
import SaveDisc from './svg/icon-save-disc.svg';
import SaveFile from './svg/icon-save-file.svg';
import SortDesc from './svg/icon-sort-desc.svg';
import Calendar from './svg/icon-calendar.svg';
import Advanced from './svg/icon-advanced.svg';
import Bookmark from './svg/icon-bookmark.svg';
import Database from './svg/icon-database.svg';
import Function from './svg/icon-function.svg';
import Resource from './svg/icon-resource.svg';
import Settings from './svg/icon-settings.svg';
import Subtract from './svg/icon-subtract.svg';
import Template from './svg/icon-template.svg';
import WeakSpot from './svg/icon-weakspot.svg';
import SortAsc from './svg/icon-sort-asc.svg';
import TrendUp from './svg/icon-trend-up.svg';
import Connect from './svg/icon-connect.svg';
import Archive from './svg/icon-archive.svg';
import Console from './svg/icon-console.svg';
import Datadog from './svg/icon-datadog.svg';
import Ellipse from './svg/icon-ellipse.svg';
import Enlarge from './svg/icon-enlarge.svg';
import Gatling from './svg/icon-gatling.svg';
import Instana from './svg/icon-instana.svg';
import Logfile from './svg/icon-logfile.svg';
import Message from './svg/icon-message.svg';
import Network from './svg/icon-network.svg';
import Postman from './svg/icon-postman.svg';
import Quality from './svg/icon-quality.svg';
import Refresh from './svg/icon-refresh.svg';
import Team10 from './svg/teams/team-10.svg';
import Team11 from './svg/teams/team-11.svg';
import Team12 from './svg/teams/team-12.svg';
import Warning from './svg/icon-warning.svg';
import NewTab from './svg/icon-new-tab.svg';
import OnPrem from './svg/icon-on-prem.svg';
import Run from './svg/icon-run-single.svg';
import StopV2 from './svg/icon-stop-v2.svg';
import Upload from './svg/icon-upload.svg';
import Cancel from './svg/icon-cancel.svg';
import Delete from './svg/icon-delete.svg';
import Docker from './svg/icon-docker.svg';
import Explore from './svg/icon-table.svg';
import Filter from './svg/icon-filter.svg';
import GitHub from './svg/icon-github.svg';
import Handle from './svg/icon-handle.svg';
import JMeter from './svg/icon-jmeter.svg';
import Logout from './svg/icon-logout.svg';
import Policy from './svg/icon-policy.svg';
import Reduce from './svg/icon-reduce.svg';
import Remove from './svg/icon-remove.svg';
import Report from './svg/icon-report.svg';
import Ripple from './svg/icon-ripple.svg';
import Search from './svg/icon-search.svg';
import Spring from './svg/icon-spring.svg';
import Table from './svg/icon-explore.svg';
import Target from './svg/icon-target.svg';
import Team1 from './svg/teams/team-1.svg';
import Team2 from './svg/teams/team-2.svg';
import Team3 from './svg/teams/team-3.svg';
import Team4 from './svg/teams/team-4.svg';
import Team5 from './svg/teams/team-5.svg';
import Team6 from './svg/teams/team-6.svg';
import Team7 from './svg/teams/team-7.svg';
import Team8 from './svg/teams/team-8.svg';
import Team9 from './svg/teams/team-9.svg';
import Wizard from './svg/icon-wizard.svg';
import ToEnd from './svg/icon-to-end.svg';
import Agent from './svg/icon-agent.svg';
import Azure from './svg/icon-azure.svg';
import Badge from './svg/icon-badge.svg';
import Check from './svg/icon-check.svg';
import Clock from './svg/icon-clock.svg';
import Close from './svg/icon-close.svg';
import Debug from './svg/icon-debug.svg';
import Delay from './svg/icon-delay.svg';
import Group from './svg/icon-group.svg';
import Kafka from './svg/icon-kafka.svg';
import Linux from './svg/icon-linux.svg';
import Reset from './svg/icon-reset.svg';
import Share from './svg/icon-share.svg';
import Slack from './svg/icon-slack.svg';
import Sleep from './svg/icon-sleep.svg';
import Start from './svg/icon-start.svg';
import State from './svg/icon-state.svg';
import Trash from './svg/icon-trash.svg';
import Runs from './svg/icon-runs.svg';
import Crio from './svg/icon-crio.svg';
import Edit from './svg/icon-edit.svg';
import Help from './svg/icon-help.svg';
import Hide from './svg/icon-hide.svg';
import Home from './svg/icon-home.svg';
import Host from './svg/icon-host.svg';
import Java from './svg/icon-java.svg';
import Kong from './svg/icon-kong.svg';
import Lock from './svg/icon-lock.svg';
import Logo from './svg/icon-logo.svg';
import Show from './svg/icon-show.svg';
import Stop from './svg/icon-stop.svg';
import Tags from './svg/icon-tags.svg';
import Team from './svg/icon-team.svg';
import User from './svg/icon-user.svg';
import View from './svg/icon-view.svg';
import Hub from './svg/icon-hub.svg';
import Add from './svg/icon-add.svg';
import Aws from './svg/icon-aws.svg';
import Cpu from './svg/icon-cpu.svg';
import Dot from './svg/icon-dot.svg';
import Eks from './svg/icon-eks.svg';
import Fav from './svg/icon-fav.svg';
import Fix from './svg/icon-fix.svg';
import K8s from './svg/icon-k8s.svg';
import Nip from './svg/icon-nip.svg';
import K6 from './svg/icon-k6.svg';

export type IconComponentProps = React.PropsWithoutRef<React.SVGProps<SVGSVGElement> & ContainerProps> &
	React.RefAttributes<SVGSVGElement>;

export type IconComponent = React.ForwardRefExoticComponent<IconComponentProps> & {
	Svg: React.VFC<React.SVGProps<SVGSVGElement>>;
};

const createIcon = (svg: React.VFC<React.SVGProps<SVGSVGElement>>): IconComponent => {
	const boxComponent = React.forwardRef<HTMLElement, React.SVGProps<SVGSVGElement> & IconComponentProps>(
		function CreateIconComponent(
			{
				variant = 'medium',
				sx = {},
				minHeight,
				minWidth,
				maxHeight,
				maxWidth,
				height,
				width,
				m,
				mb,
				ml,
				mr,
				mt,
				mx,
				my,
				p,
				pb,
				pl,
				pr,
				pt,
				px,
				py,
				opacity,
				...props
			},
			ref,
		) {
			return React.createElement(ContainerComponent, {
				as: svg,
				...props,
				variant: `icons.${variant}`,
				sx: {
					verticalAlign: 'middle',
					height: resolveStyleProp(height),
					minHeight: resolveStyleProp(minHeight),
					maxHeight: resolveStyleProp(maxHeight),
					width: resolveStyleProp(width),
					minWidth: resolveStyleProp(minWidth),
					maxWidth: resolveStyleProp(maxWidth),
					m: resolveStyleProp(m),
					mb: resolveStyleProp(mb),
					ml: resolveStyleProp(ml),
					mr: resolveStyleProp(mr),
					mt: resolveStyleProp(mt),
					mx: resolveStyleProp(mx),
					my: resolveStyleProp(my),
					p: resolveStyleProp(p),
					pb: resolveStyleProp(pb),
					pl: resolveStyleProp(pl),
					pr: resolveStyleProp(pr),
					pt: resolveStyleProp(pt),
					px: resolveStyleProp(px),
					py: resolveStyleProp(py),
					opacity: resolveStyleProp(opacity),
					...resolveSx(sx),
				},
				ref,
			});
		},
	);

	(boxComponent as IconComponent).Svg = svg;

	return boxComponent as IconComponent;
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// Memoization is necessary to avoid constant re-creaction of React components. When re-creating React components,
// the component state is lost and React is forced to fully re-render the component thus causing a performance hit
// and flickering in the UI.
//
// The generated SVG is rather complicated because we need to be able to colorize the image just like any other
// SVG icon. Since users can provide any kind of data format, the process is surprisingly complicated.
export const createIconFromDataUri = memoize(function createIconFromDataUri(
	dataUri: string | null | undefined,
): IconComponent {
	// We want to reuse this repeating structure as often as possible to avoid unnecessary re-creaction of React
	// component trees.
	let content: ReactElement | null = null;

	// Safari does not yet support filters on SVG images. We therefore just show whatever is the default color
	// of the icon :(
	if (isSafari && dataUri) {
		content = <image href={dataUri} x="0" y="0" height="100%" width="100%" />;
	} else if (dataUri) {
		const hash = getHash(dataUri ?? '');
		const maskId = `icon-mask-${hash}`;

		content = (
			<>
				<defs>
					<mask id={maskId}>
						<image
							href={dataUri}
							x="0"
							y="0"
							height="100%"
							width="100%"
							style={{
								filter: 'brightness(0) invert(1)',
							}}
						/>
					</mask>
				</defs>

				<rect fill="currentColor" x="0" y="0" height="100%" width="100%" mask={`url(#${maskId})`} />
			</>
		);
	}

	function DataUriIcon(props: React.SVGProps<SVGSVGElement>): ReactElement {
		return (
			<svg version="1.1" viewBox="0 0 1 1" width="100%" {...props}>
				{content}
			</svg>
		);
	}

	return createIcon(DataUriIcon);
});

export const IconActivityHide = createIcon(ActivityHide);
export const IconActivityShow = createIcon(ActivityShow);
export const IconAdd = createIcon(Add);
export const IconAgent = createIcon(Agent);
export const IconApiAccessToken = createIcon(ApiAccessToken);
export const IconResilienceAchieved = createIcon(ResilienceAchieved);
export const IconAws = createIcon(Aws);
export const IconRemove = createIcon(Remove);
export const IconAlertDiamond = createIcon(AlertDiamond);
export const IconAlertTriangle = createIcon(AlertTriangle);
export const IconArrowDropDown = createIcon(ArrowDropDown);
export const IconArrowDropUp = createIcon(ArrowDropUp);
export const IconArrowLeft = createIcon(ArrowLeft);
export const IconArrowRight = createIcon(ArrowRight);
export const IconArrowRightSmall = createIcon(ArrowRightSmall);
export const IconCancel = createIcon(Cancel);
export const IconCalendarClock = createIcon(CalendarClock);
export const IconCalendarDisable = createIcon(CalendarDisable);
export const IconCheck = createIcon(Check);
export const IconLock = createIcon(Lock);
export const IconWeakSpot = createIcon(WeakSpot);
export const IconExperiment2 = createIcon(Experiment2);
export const IconCheckFilled = createIcon(CheckFilled);
export const IconCheckRound = createIcon(CheckRound);
export const IconDot = createIcon(Dot);
export const IconChevronDown = createIcon(ChevronDown);
export const IconChevronDoubleUp = createIcon(ChevronDoubleUp);
export const IconChevronDoubleDown = createIcon(ChevronDoubleDown);
export const IconChevronDoubleLeft = createIcon(ChevronDoubleLeft);
export const IconChevronDoubleRight = createIcon(ChevronDoubleRight);
export const IconChevronRight = createIcon(ChevronRight);
export const IconChevronLeft = createIcon(ChevronLeft);
export const IconChevronBeginning = createIcon(ChevronBeginning);
export const IconChevronEnd = createIcon(ChevronEnd);
export const IconChevronUp = createIcon(ChevronUp);
export const IconChevronSmallUp = createIcon(ChevronSmallUp);
export const IconChevronSmallDown = createIcon(ChevronSmallDown);
export const IconMaintenance = createIcon(Maintenance);
export const IconClock = createIcon(Clock);
export const IconClose = createIcon(Close);
export const IconCpu = createIcon(Cpu);
export const IconCreateFromScratch = createIcon(CreateFromScratch);
export const IconDelay = createIcon(Delay);
export const IconDelete = createIcon(Delete);
export const IconDuplicate = createIcon(Duplicate);
export const IconEdit = createIcon(Edit);
export const IconFailedRound = createIcon(FailedRound);
export const IconFav = createIcon(Fav);
export const IconFilter = createIcon(Filter);
export const IconFavFilled = createIcon(FavFilled);
export const IconHandle = createIcon(Handle);
export const IconHelp = createIcon(Help);
export const IconHost = createIcon(Host);
export const IconIntegrations = createIcon(Integrations);
export const IconLogo = createIcon(Logo);
export const IconNetwork = createIcon(Network);
export const IconNewTab = createIcon(NewTab);
export const IconUnarchive = createIcon(Unarchive);
export const IconClipboard = createIcon(Clipboard);
export const IconResource = createIcon(Resource);
export const IconSearch = createIcon(Search);
export const IconSettings = createIcon(Settings);
export const IconAdvanced = createIcon(Advanced);
export const IconDistribution = createIcon(Distribution);
export const IconSortAsc = createIcon(SortAsc);
export const IconSortDesc = createIcon(SortDesc);
export const IconStart = createIcon(Start);
export const IconState = createIcon(State);
export const IconStop = createIcon(Stop);
export const IconSubtract = createIcon(Subtract);
export const IconTarget = createIcon(Target);
export const IconTargetEnrichmentRule = createIcon(TargetEnrichmentRule);
export const IconSandGlass = createIcon(SandGlass);
export const IconContainer = createIcon(Container);
export const IconContainerD = createIcon(ContainerD);
export const IconContainerRegistry = createIcon(ContainerRegistry);
export const IconApplication = createIcon(Application);
export const IconK8s = createIcon(K8s);
export const IconLogfile = createIcon(Logfile);
export const IconKubernetes = createIcon(Kubernetes);
export const IconSaveDisc = createIcon(SaveDisc);
export const IconTeam = createIcon(Team);
export const IconDescription = createIcon(Description);
export const IconEnvironment = createIcon(Environment);
export const IconEnvironmentGlobal = createIcon(EnvironmentGlobal);
export const IconDiscoveryKit = createIcon(DiscoveryKit);
export const IconActionKit = createIcon(ActionKit);
export const IconEnvironmentAdvanced = createIcon(EnvironmentAdvanced);
export const IconVerticalDivider = createIcon(VerticalDivider);
export const IconInProgress = createIcon(InProgress);
export const IconTargetIndicatorAttack = createIcon(TargetIndicatorAttack);
export const IconBlastRadius = createIcon(BlastRadius);
export const IconTags = createIcon(Tags);
export const IconSlack = createIcon(Slack);
export const IconInstana = createIcon(Instana);
export const IconNewRelic = createIcon(NewRelic);
export const IconDatadog = createIcon(Datadog);
export const IconDatabase = createIcon(Database);
export const IconPrometheus = createIcon(Prometheus);
export const IconSaveDraft = createIcon(SaveDraft);
export const IconSaveFile = createIcon(SaveFile);
export const IconShow = createIcon(Show);
export const IconHide = createIcon(Hide);
export const IconWarning = createIcon(Warning);
export const IconWarningSmall = createIcon(WarningSmall);
export const IconWarningCircle = createIcon(WarningCircle);
export const IconWarningCircleOpen = createIcon(WarningCircleOpen);
export const IconMessage = createIcon(Message);
export const IconNavigationMenuHorizontal = createIcon(NavigationMenuHorizontal);
export const IconNavigationMenuVertical = createIcon(NavigationMenuVertical);
export const IconInformationCircle = createIcon(InformationCircle);
export const IconInformation = createIcon(Information);
export const IconView = createIcon(View);
export const IconReport = createIcon(Report);
export const IconLinux = createIcon(Linux);
export const IconDocker = createIcon(Docker);
export const IconCrio = createIcon(Crio);
export const IconJava = createIcon(Java);
export const IconOpenshift = createIcon(Openshift);
export const IconSpring = createIcon(Spring);
export const IconSpringBoot = createIcon(SpringBoot);
export const IconEks = createIcon(Eks);
export const IconAzure = createIcon(Azure);
export const IconGoogleCloud = createIcon(GoogleCloudPlatform);
export const IconExperiment = createIcon(Experiment);
export const IconExperimentBlocked = createIcon(ExperimentBlocked);
export const IconGroup = createIcon(Group);
export const IconShare = createIcon(Share);
export const IconTrendUp = createIcon(TrendUp);
export const IconTrendStatic = createIcon(TrendStatic);
export const IconTrendDown = createIcon(TrendDown);
export const IconUser = createIcon(User);
export const IconExplosion = createIcon(Explosion);
export const IconRefresh = createIcon(Refresh);
export const IconRefreshArrow = createIcon(RefreshArrow);
export const IconFix = createIcon(Fix);
export const IconRunAgain = createIcon(RunAgain);
export const IconRun = createIcon(Run);
export const IconRunV2 = createIcon(RunV2);
export const IconStopV2 = createIcon(StopV2);
export const IconToEnd = createIcon(ToEnd);
export const IconNip = createIcon(Nip);
export const IconSleep = createIcon(Sleep);
export const IconRipple = createIcon(Ripple);
export const IconRoundSpinner = createIcon(RoundSpinner);
export const IconRoundSpinnerWhite = createIcon(RoundSpinnerWhite);
export const IconKubernetesPod = createIcon(KubernetesPod);
export const IconDeployment = createIcon(Deployment);
export const IconNamespace = createIcon(Namespace);
export const IconLightbulb = createIcon(Lightbulb);
export const IconStatusWaiting = createIcon(StatusWaiting);
export const IconStatusOperating = createIcon(StatusOperating);
export const IconAgentCount = createIcon(AgentCount);
export const IconEllipse = createIcon(Ellipse);
export const IconDataTransferCircle = createIcon(DataTransferCircle);
export const IconFunction = createIcon(Function);
export const IconWizard = createIcon(Wizard);
export const IconEnlarge = createIcon(Enlarge);
export const IconReduce = createIcon(Reduce);
export const IconPolicyBindingConsole = createIcon(PolicyBindingConsole);
export const IconConsole = createIcon(Console);
export const IconPolicyBindingStateUI = createIcon(PolicyBindingStateUI);
export const IconGitHub = createIcon(GitHub);
export const IconTrash = createIcon(Trash);
export const IconPolicy = createIcon(Policy);
export const IconZoomMinus = createIcon(ZoomMinus);
export const IconQuality = createIcon(Quality);
export const IconUnchecked = createIcon(Unchecked);
export const IconPolicyUnknown = createIcon(PolicyUnknown);
export const IconCollectionCall = createIcon(CollectionCall);
export const IconMetricQuery = createIcon(MetricQuery);
export const IconMetricCheck = createIcon(MetricCheck);
export const IconBadge = createIcon(Badge);
export const IconCreateScenarioFromScratch = createIcon(CreateScenarioFromScratch);
export const IconAstronaut = createIcon(Astronaut);
export const IconGatling = createIcon(Gatling);
export const IconJMeter = createIcon(JMeter);
export const IconK6 = createIcon(K6);
export const IconKafka = createIcon(Kafka);
export const IconKong = createIcon(Kong);
export const IconOnPrem = createIcon(OnPrem);
export const IconPostman = createIcon(Postman);
export const IconArchive = createIcon(Archive);
export const IconExtension = createIcon(Extension);
export const IconHome = createIcon(Home);
export const IconCalendarEdit = createIcon(CalendarEdit);
export const IconCalendarRecurrent = createIcon(CalendarRecurrent);
export const IconCalendarPaused = createIcon(CalendarPaused);
export const IconSteadybitApproved = createIcon(SteadybitApproved);
export const IconExplore = createIcon(Explore);
export const IconTable = createIcon(Table);
export const IconAdviceGeneral = createIcon(AdviceGeneral);
export const IconReset = createIcon(Reset);
export const IconBookmark = createIcon(Bookmark);
export const IconWarningOpen = createIcon(WarningOpen);
export const IconTemplate = createIcon(Template);
export const IconAdviceUnknown = createIcon(AdviceUnknown);
export const IconDocumentation = createIcon(Documentation);
export const IconNotification = createIcon(Notification);
export const IconLogout = createIcon(Logout);
export const IconDebug = createIcon(Debug);
export const IconHub = createIcon(Hub);
export const IconDisconnect = createIcon(Disconnect);
export const IconUpload = createIcon(Upload);
export const IconTemplateAdd = createIcon(TemplateAdd);
export const IconRuns = createIcon(Runs);
export const IconCalendar = createIcon(Calendar);
export const IconConnect = createIcon(Connect);
export const IconInformationCircleFilled = createIcon(InformationCircleFilled);

//Attacks
export const IconExperimentError = createIcon(ExperimentError);
export const IconExperimentFailed = createIcon(ExperimentFailed);
export const IconAttackTime = createIcon(AttackTime);
export const IconGeneralAdvice = createIcon(GeneralAdvice);
export const IconAdviceActionNeeded = createIcon(AdviceActionNeeded);
export const IconAdviceValidation = createIcon(AdviceValidation);
export const IconAdviceImplemented = createIcon(AdviceImplemented);

//Teams
export const IconTeam1 = createIcon(Team1);
export const IconTeam2 = createIcon(Team2);
export const IconTeam3 = createIcon(Team3);
export const IconTeam4 = createIcon(Team4);
export const IconTeam5 = createIcon(Team5);
export const IconTeam6 = createIcon(Team6);
export const IconTeam7 = createIcon(Team7);
export const IconTeam8 = createIcon(Team8);
export const IconTeam9 = createIcon(Team9);
export const IconTeam10 = createIcon(Team10);
export const IconTeam11 = createIcon(Team11);
export const IconTeam12 = createIcon(Team12);
